<template>
  <div class="modal fade" :id="modalWA" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">Kirim Via Whatsapp ke <b>{{numberWA}}</b></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
          <div class="form-check">
                <input class="form-check-input" value="false" name="typeWA" type="radio" v-model="typeWA" id="defaultCheck">
                <label class="form-check-label" for="defaultCheck">
                    Tanpa text
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" value="true"  name="typeWA" type="radio"  v-model="typeWA" id="WithTextValue">
                <label class="form-check-label" for="WithTextValue">
                     Menggunakan template text
                </label>
            </div>
            <div v-if="typeWA == 'true'">
                <div class="form-group">
                    <label for="">Select Template</label>
                    <select class="form-control" @change="setMessage($event)" v-model="message" :class="errorMessage? 'is-invalid': ''">
                        <option v-for="(item, index) in templateMessage" :key="index" :value="item.text">{{item.label}}</option>
                    </select>
                </div>
                <textarea class="form-control" :class="errorMessage? 'is-invalid': ''" rows="4" v-model="messageWA"></textarea>
                <small v-if="errorMessage" class="text-danger">Isi Message</small>
                
            </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="salinLink()"> <i class="la la-copy" /> Salin Link</button>
        <button type="button" class="btn btn-primary" @click="sendWA()">Kirim</button>
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal"  @click="close()">Tutup</button> -->
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { RepositoryFactory } from "./../../../../repositories/RepositoryFactory"
const ResourceRepository = RepositoryFactory.get("printerResource");
const ReservationRepository = RepositoryFactory.get('reservation')
import EnumWhatsapp from '../../../../constants/telepon-enum'
export default {
    data(){
        return{
            typeWA: false,
            message: '',
            messageWA: '',
            templateMessage: [
            ],
            errorMessage: false,
            objData: {}
        }
    },
    watch: {
        messageWA(val){
            if(val) {
                this.errorMessage = false
            }
        }
    },
    props: {
        modalWA: { type: String, default: "modalWA" },
        value: Object,
        idAppointment: {type: String, default: ""}
    },
    computed: {
        ...mapState('DayCalendarStore', {
          showModalWa: state => state.showModalWa,
          numberWA: state => state.numberWA,
      }),
    },
    methods:{
        ...mapMutations({
                setShowModalWa:'DayCalendarStore/SET_SHOW_MODAL_WA',
        }),
        salinLink() {
          const url = `https://wa.me/${this.numberWA}`
          const urlText = `${url}?text=${encodeURI(this.messageWA)}`
          navigator.clipboard.writeText(urlText);
          toastr.options = {
            timeOut: 1000
          }
          toastr.success('Berhasil disalin')
        },
       close() {
            $("#" + this.modalWA).modal("hide");
            this.setShowModalWa(false);
        },
        sendWA(){
            const url = `https://wa.me/${this.numberWA}`
            if(this.typeWA == 'true') {
                if(this.messageWA) {
                     window.open(`${url}?text=${encodeURI(this.messageWA)}`, "_blank");
                } else {
                  this.errorMessage = true
                }
                
            } else {
                window.open(`${url}`, "_blank");
            }
            
        },
        async getServerList(){
                const { data } = await ResourceRepository.getBackOfficeEnv()
                this.templateMessage = data.templateMessage ? data.templateMessage: []
            
        },
        async getDataAppointment (id) {
           const {data} = await ReservationRepository.getReservationAppointmentBackoffice(id)
           return data
        },
        setMessage(event) {
          let objReservasi = this.objData
          const listPerubahan = this.getVarData(event.target.value)
          let dataMessage = event.target.value
          
          listPerubahan.forEach(element => {
            dataMessage = dataMessage.replace(element, this.getInObjectKey(element, objReservasi))
          });
          this.messageWA = dataMessage
          // this.messageWA = event.target.value
        },
        getVarData(textData) {
          const arrayMatch = textData.match(/\{[a-zA-Z0-9\.\-]+\}/g)
          return arrayMatch ? arrayMatch : [];
        },
        getInObjectKey(keyOri, data) {
          let key = keyOri.replace('{', '').replace('}', '')
          const arrayKey = key.split('.')
          var typeStatus = ''
          const dataReduce = arrayKey.reduce((prev, next) => {
            let nextKey = next
            if (nextKey.indexOf(EnumWhatsapp.TIME) ==0) {
              typeStatus = EnumWhatsapp.TIME
              nextKey = nextKey.replace(EnumWhatsapp.TIME, '')
            } else if (nextKey.indexOf(EnumWhatsapp.LOWER_CASE) == 0) {
              typeStatus = EnumWhatsapp.LOWER_CASE
              nextKey = nextKey.replace(EnumWhatsapp.LOWER_CASE, '')
            } else if (nextKey.indexOf(EnumWhatsapp.TIME_EN) == 0) {
              typeStatus = EnumWhatsapp.TIME_EN
              nextKey = nextKey.replace(EnumWhatsapp.TIME_EN, '')
            } else if (nextKey.indexOf(EnumWhatsapp.UPPER_CASE) == 0) {
              typeStatus = EnumWhatsapp.UPPER_CASE
              nextKey = nextKey.replace(EnumWhatsapp.UPPER_CASE, '')
            } else if (nextKey.indexOf(EnumWhatsapp.CAPITALIZE_CASE) == 0) {
              typeStatus = EnumWhatsapp.CAPITALIZE_CASE
              nextKey = nextKey.replace(EnumWhatsapp.CAPITALIZE_CASE, '')
            } else if (nextKey.indexOf(EnumWhatsapp.CAPITALIEZE_CASE_ALL) == 0) {
              typeStatus = EnumWhatsapp.CAPITALIEZE_CASE_ALL
              nextKey = nextKey.replace(EnumWhatsapp.CAPITALIEZE_CASE_ALL, '')
            } 
            prev = prev[nextKey]
            
            if(prev) return prev
            return {}
          }, data)
          if (typeof dataReduce == 'object') {
            return keyOri
          }
          if (typeStatus) return this.mappingSource(dataReduce, typeStatus)
          return dataReduce
        },
        mappingSource(data, type) {
          if (type == EnumWhatsapp.TIME) {
            return moment(data).format('DD MMMM YYYY')
          } else if (type == EnumWhatsapp.LOWER_CASE) {
            return data.toLowerCase()
          } else if (type == EnumWhatsapp.UPPER_CASE) {
            return data.toUpperCase()
          } else if(type == EnumWhatsapp.TIME_EN) {
            moment.locale('en')
            return moment(data).format('DD MMMM YYYY')
          } else if (type == EnumWhatsapp.CAPITALIZE_CASE) {
            const lower = data.toLowerCase()
            return data.charAt(0).toUpperCase() + lower.slice(1)
          } else if (type == EnumWhatsapp.CAPITALIEZE_CASE_ALL) {
                return data
                  .toLowerCase()
                  .split(' ')
                  .map(element => element.charAt(0).toUpperCase() + element.slice(1))
                  .join(' ');
          }
        }
    },
    async mounted () {
      $("#" + this.modalWA ).modal();
      await this.getServerList()
      this.objData = await this.getDataAppointment(this.idAppointment)
    },
    destroyed() {
      this.setShowModalWa(false);
    },
}
</script>

<style>

</style>